import {controller, target, targets} from '@github/catalyst'
import type MetricSelectionElement from './metric-selection-element'
import {debounce} from '@github/mini-throttle/decorators'
import {validate} from '../../assets/modules/github/behaviors/html-validation'

const validChoices: {[index: string]: {[index: string]: boolean}} = {
  AV: {
    N: true,
    A: true,
    L: true,
    P: true
  },
  AC: {
    L: true,
    H: true
  },
  PR: {
    N: true,
    L: true,
    H: true
  },
  UI: {
    N: true,
    R: true
  },
  S: {
    U: true,
    C: true
  },
  C: {
    N: true,
    L: true,
    H: true
  },
  I: {
    N: true,
    L: true,
    H: true
  },
  A: {
    N: true,
    L: true,
    H: true
  }
}

interface ScoreData {
  score: number
  severity: string
}

const severityClassNames: {[index: string]: string} = {
  critical: 'Label--danger',
  high: 'Label--orange',
  moderate: 'Label--warning',
  low: 'Label--primary'
}

@controller
export default class SeverityCalculatorElement extends HTMLElement {
  @target vectorStringFieldElement: HTMLElement
  @target vectorStringInput: HTMLInputElement
  @target vectorStringError: HTMLElement
  @targets metricSelectionElements: MetricSelectionElement[]
  @target scoreFieldElement: HTMLElement
  @target scoreAuthenticityTokenInput: HTMLInputElement
  @target scoreElement: HTMLElement
  @target severityLabelElement: HTMLElement

  readonly pendingScoreTextColorClass = 'color-text-tertiary'

  hide() {
    this.hidden = true
    this.vectorStringInput.required = false

    this.hideFormGroupError(this.vectorStringFieldElement)
    this.vectorStringInput.setCustomValidity('')
    validate(this.vectorStringInput.form!)
  }

  show() {
    this.hidden = false
    this.vectorStringInput.required = true

    if (this.vectorStringInput.value !== '') {
      this.validateVectorStringInput()
    }
  }

  hideFormGroupError(formGroup: HTMLElement) {
    if (formGroup.classList.contains('errored')) {
      formGroup.classList.remove('errored')
    }
  }

  showFormGroupError(formGroup: HTMLElement) {
    if (!formGroup.classList.contains('errored')) {
      formGroup.classList.add('errored')
    }
  }

  isValidCVSS3(vectorString: string): boolean {
    return this.buildCVSS3ErrorMessage(vectorString) === ''
  }

  buildCVSS3ErrorMessage(vectorString: string): string {
    if (vectorString === '') {
      return this.vectorStringError.getAttribute('data-empty-cvss-error-message')!
    }

    const cvssV3Pattern = /^CVSS:3\.\d+(\/[^:]+:[^:]+){8}$/

    if (!cvssV3Pattern.test(vectorString)) {
      return this.vectorStringError.getAttribute('data-error-message')!
    }

    // further check whether the typed code pairs are valid
    const keyValuePairs: string[] = vectorString.split('/').slice(1)
    for (const keyValuePair of keyValuePairs) {
      const [metricCode, value] = keyValuePair.split(':')

      if (!validChoices[metricCode]) {
        const errorText = this.vectorStringError.getAttribute('data-invalid-metric-error-message')!
        return errorText.replace('{}', metricCode)
      }

      if (!validChoices[metricCode][value]) {
        const errorText = this.vectorStringError.getAttribute('data-invalid-metric-value-error-message')!
        return errorText.replace('{}', value).replace('{}', metricCode)
      }
    }

    return ''
  }

  // Returns true if a vector string was typed and it was valid.
  validateVectorStringInput(): boolean {
    const error: string = this.buildCVSS3ErrorMessage(this.vectorStringInput.value)

    if (!error) {
      this.hideFormGroupError(this.vectorStringFieldElement)
      this.vectorStringInput.setCustomValidity('')
    } else {
      this.vectorStringError.textContent = error
      this.showFormGroupError(this.vectorStringFieldElement)
      this.vectorStringInput.setCustomValidity('error')
    }

    validate(this.vectorStringInput.form!)

    return !error
  }

  handleVectorStringBlur() {
    if (this.validateVectorStringInput()) {
      this.calculateScore()
      this.scoreElement.classList.remove(this.pendingScoreTextColorClass)
    } else {
      this.hideSeverityLabel()
      this.scoreElement.textContent = this.scoreElement.getAttribute('data-empty-message')
      this.scoreElement.classList.add(this.pendingScoreTextColorClass)
    }
  }

  connectedCallback() {
    this.handleVectorStringInput()
  }

  @debounce(100)
  handleVectorStringInput() {
    const newVectorString: string = this.vectorStringInput.value

    const keyValuePairs: string[] = newVectorString.split('/').slice(1)
    const metricSelections: {[index: string]: string} = keyValuePairs.reduce((selectionHash, keyValuePair) => {
      const [metricCode, value] = keyValuePair.split(':')

      return {
        ...selectionHash,
        [metricCode]: value
      }
    }, {})

    for (const metricSelectionElement of this.metricSelectionElements) {
      const metricCode: string = metricSelectionElement.metricCode!
      const selectionCode = metricSelections[metricCode]
      metricSelectionElement.selectFromCode(selectionCode)
    }
  }

  regenerateVectorString() {
    let vectorString = 'CVSS:3.1'

    for (const metricSelectionElement of this.metricSelectionElements) {
      const metricCode = metricSelectionElement.metricCode
      const selectedValue = metricSelectionElement.selectedValue || '_'

      vectorString += `/${metricCode}:${selectedValue}`
    }

    this.vectorStringInput.value = vectorString
  }

  // Used when the vector string is modified using the user interface controls.
  handleMetricSelectionChange() {
    this.regenerateVectorString()
    this.hideFormGroupError(this.vectorStringFieldElement)

    // only compute the score if all the controls have been selected
    if (this.isValidCVSS3(this.vectorStringInput.value)) {
      this.vectorStringInput.setCustomValidity('')
      this.calculateScore()
      this.scoreElement.classList.remove(this.pendingScoreTextColorClass)
    } else {
      this.vectorStringInput.setCustomValidity('error')
    }

    validate(this.vectorStringInput.form!)
  }

  async calculateScore() {
    this.hideFormGroupError(this.scoreFieldElement)

    let scoreData: ScoreData
    try {
      scoreData = await this.fetchScoreData()
    } catch (error) {
      this.showFormGroupError(this.scoreFieldElement)
      return
    }

    this.scoreElement.textContent = scoreData.score.toFixed(1)

    const severityLabel = scoreData.severity.toLowerCase()
    this.showSeverityLabel(severityLabel)
  }

  async fetchScoreData(): Promise<ScoreData> {
    const formData = new FormData()
    formData.append('cvss_v3', this.vectorStringInput.value)

    const url = this.scoreElement.getAttribute('data-action-url')
    if (!url) {
      throw new Error('The endpoint url to get the score must be specified')
    }

    const response = await fetch(url, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Scoped-CSRF-Token': this.scoreAuthenticityTokenInput.value
      },
      body: formData
    })
    if (!response.ok) {
      return Promise.reject(new Error('Score could not be calculated'))
    }

    return response.json()
  }

  hideSeverityLabel() {
    this.severityLabelElement.hidden = true
  }

  showSeverityLabel(severityLabel: string) {
    this.severityLabelElement.textContent = severityLabel

    // update the label style
    const className = severityClassNames[severityLabel]
    if (!this.severityLabelElement.classList.contains(className)) {
      this.severityLabelElement.classList.remove(...Object.values(severityClassNames))

      this.severityLabelElement.classList.add(className)
    }

    if (this.severityLabelElement.hidden) {
      this.severityLabelElement.hidden = false
    }
  }
}
